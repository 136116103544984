/* eslint-disable import/prefer-default-export */
import React from 'react'
import Loadable from 'react-loadable'

import Loading from 'components/Loading'

const LoadingComponent = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh'
    }}
  >
    <Loading show />
  </div>
)

export const AsyncSignin = Loadable({
  loader: () => import('./Signin/index'),
  loading: LoadingComponent
})

export const AsyncSignup = Loadable({
  loader: () => import('./Signup/index'),
  loading: LoadingComponent
})

export const AsyncForgetPassword = Loadable({
  loader: () => import('./ForgetPassword/index'),
  loading: LoadingComponent
})

export const AsyncResetPassword = Loadable({
  loader: () => import('./ResetPassword/index'),
  loading: LoadingComponent
})

export const AsyncConfirmationCode = Loadable({
  loader: () => import('./ConfirmationCode/index'),
  loading: LoadingComponent
})

export const AsyncOnboarding = Loadable({
  loader: () => import('./Onboarding/Onboarding'),
  loading: LoadingComponent
})

export const AsyncHome = Loadable({
  loader: () => import('./Home/index'),
  loading: LoadingComponent
})

export const AsyncPlanning = Loadable({
  loader: () => import('./Planning/index'),
  loading: LoadingComponent
})

export const AsyncAssistant = Loadable({
  loader: () => import('./Assistant/index'),
  loading: LoadingComponent
})

export const AsyncInvoicing = Loadable({
  loader: () => import('./Invoicing/index'),
  loading: LoadingComponent
})

export const AsyncInvoicingNew = Loadable({
  loader: () => import('./InvoicingNew/index'),
  loading: LoadingComponent
})

export const AsyncInvoicingItem = Loadable({
  loader: () => import('./InvoicingItem/index'),
  loading: LoadingComponent
})

export const AsyncInvoicingHistory = Loadable({
  loader: () => import('./InvoicingHistory/index'),
  loading: LoadingComponent
})

export const AsyncTimeEntry = Loadable({
  loader: () => import('./TimeEntry/index'),
  loading: LoadingComponent
})

export const AsyncTimeApproval = Loadable({
  loader: () => import('./TimeApproval/index'),
  loading: LoadingComponent
})

export const AsyncReports = Loadable({
  loader: () => import('./Reports/index'),
  loading: LoadingComponent
})

export const AsyncSettings = Loadable({
  loader: () => import('./Settings/index'),
  loading: LoadingComponent
})

export const AsyncClients = Loadable({
  loader: () => import('./Clients/index'),
  loading: LoadingComponent
})

export const AsyncClientsAdd = Loadable({
  loader: () => import('./ClientsAdd/index'),
  loading: LoadingComponent
})

export const AsyncProjects = Loadable({
  loader: () => import('./Projects/index'),
  loading: LoadingComponent
})

export const AsyncProjectsAdd = Loadable({
  loader: () => import('./ProjectsAdd/index'),
  loading: LoadingComponent
})

export const AsyncMembers = Loadable({
  loader: () => import('./Members/index'),
  loading: LoadingComponent
})

export const AsyncMembersAdd = Loadable({
  loader: () => import('./MembersAdd/index'),
  loading: LoadingComponent
})

export const AsyncProjectRoles = Loadable({
  loader: () => import('./ProjectRoles/index'),
  loading: LoadingComponent
})

export const AsyncTags = Loadable({
  loader: () => import('./Tags/index'),
  loading: LoadingComponent
})

export const AsyncCompany = Loadable({
  loader: () => import('./Company/index'),
  loading: LoadingComponent
})

export const AsyncInvoiceSetting = Loadable({
  loader: () => import('./InvoiceSetting/index'),
  loading: LoadingComponent
})

export const AsyncProfile = Loadable({
  loader: () => import('./Profile/index'),
  loading: LoadingComponent
})

export const AsyncSubscription = Loadable({
  loader: () => import('./Subscription/index'),
  loading: LoadingComponent
})

export const AsyncSubscriptionInvoices = Loadable({
  loader: () => import('./SubscriptionInvoices/index'),
  loading: LoadingComponent
})

export const AsyncSubscriptionBilling = Loadable({
  loader: () => import('./SubscriptionBilling/index'),
  loading: LoadingComponent
})

export const AsyncDashboard = Loadable({
  loader: () => import('./Dashboard/index'),
  loading: LoadingComponent
})

export const AsyncIntegrations = Loadable({
  loader: () => import('./integrations/index'),
  loading: LoadingComponent
})

export const AsyncIntegrationsHistory = Loadable({
  loader: () => import('./integrations/invoiceHistory/index'),
  loading: LoadingComponent
})

export const AsyncActivityLog = Loadable({
  loader: () => import('./ActivityLog/index'),
  loading: LoadingComponent
})

export const AsyncProjectsPage = Loadable({
  loader: () => import('./ProjectsPage/index'),
  loading: LoadingComponent
})
