import React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import cx from 'clsx'

import s from './Tabs.module.scss'

function SimpleTabs({
  items,
  activeTab,
  onChange,
  classes,
  disabledIndex,
  loading,
  hasMoreItems,
  unreadCount
}) {
  const isTabDisabled = tabIndex => {
    return typeof disabledIndex === 'number' ? disabledIndex === tabIndex : false
  }
  return (
    <div className={cx(s.container, classes.root)}>
      <Tabs
        value={activeTab}
        onChange={onChange}
        classes={{
          root: cx(s.tabs, classes.tabs),
          indicator: cx(s.tabsIndicator, classes.tabsIndicator),
          flexContainer: cx(s.tabsFlexContainer, classes.tabsFlexContainer),
          fixed: cx(s.tabsFixedContainer, classes.tabsFixedContainer)
        }}
        TabIndicatorProps={{
          children: (
            <div className={s.tabLabel}>
              <span className={cx(s.indicatorText, classes.indicatorText)}>
                {items[activeTab]?.title}
              </span>
              {activeTab === 0 && unreadCount > 0 && (
                <span className={s.badge}>{unreadCount}</span>
              )}
            </div>
          )
        }}
      >
        {items.map((tab, tabIndex) => (
          <Tab
            key={tab.key}
            label={
              <div className={s.tabLabel}>
                {tab.title}
                {tabIndex === 0 && unreadCount > 0 && (
                  <span className={s.badge}>{unreadCount}</span>
                )}
              </div>
            }
            data-testid={tab.key}
            classes={{
              root: cx(s.tab, classes.tab),
              selected: cx(s.selectedTab, classes.selectedTab),
              disabled: cx({ [s.disabledTab]: isTabDisabled(tabIndex) })
            }}
            disabled={loading}
          />
        ))}
      </Tabs>
    </div>
  )
}

SimpleTabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string
    })
  ).isRequired,
  activeTab: PropTypes.number.isRequired,
  disabledIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
    tabs: PropTypes.string,
    tabsIndicator: PropTypes.string,
    tabsFlexContainer: PropTypes.string,
    tabsFixedContainer: PropTypes.string,
    indicatorText: PropTypes.string,
    tab: PropTypes.string,
    selectedTab: PropTypes.string
  }),
  unreadCount: PropTypes.number
}

SimpleTabs.defaultProps = {
  classes: {
    root: '',
    tabs: '',
    tabsIndicator: '',
    tabsFlexContainer: '',
    tabsFixedContainer: '',
    indicatorText: '',
    tab: '',
    selectedTab: ''
  },
  disabledIndex: false,
  unreadCount: 0
}

export default SimpleTabs
