import React from 'react'
import ReactDOM from 'react-dom'
// import * as Sentry from '@sentry/react'

import App from 'routes'
import 'theme/index.scss'
import 'i18n'
import * as serviceWorker from './serviceWorker'

// Sentry.init({
//   dsn:
//     'https://fbc52a0b8e25da7b3e6ce76b6c29d459@o4508714736156672.ingest.de.sentry.io/4508714802610256',
//   integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// })

ReactDOM.render(
  <React.Suspense fallback=" ">
    <App />
  </React.Suspense>,
  document.getElementById('root')
)

serviceWorker.unregister()
// serviceWorker.register({
//   onUpdate: registration => {
//     // Optionally, you can handle the update callback here
//   },
//   onSuccess: registration => {
//     console.log('Service Worker registered successfully:', registration)
//   }
// })
