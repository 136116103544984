// Types
export const SET_PROJECTS_LIST = 'SET_PROJECTS_LIST'
export const SET_ROUTE = 'SET_ROUTE'
export const SET_BILLING_RATE_CHANGED = 'SET_BILLING_RATE_CHANGED'
export const SET_UNHANDLED_RATE = 'SET_UNHANDLED_RATE'
export const SET_PROJECT_BUDGET_BILLING_CHANGED = 'SET_PROJECT_BUDGET_BILLING_CHANGED'
export const SET_CLIENTS_LIST = 'SET_CLIENTS_LIST'

// Creators
export const setProjectsList = projects => ({
  type: SET_PROJECTS_LIST,
  payload: projects
})

export const setRoute = payload => ({
  type: SET_ROUTE,
  payload
})

export const memberBillingRateChanged = payload => ({
  type: SET_BILLING_RATE_CHANGED,
  payload
})

export const setUnhandledRate = payload => ({
  type: SET_UNHANDLED_RATE,
  payload
})

export const projectBudgetBillingChanged = payload => ({
  type: SET_PROJECT_BUDGET_BILLING_CHANGED,
  payload
})

export const setClientsList = clients => ({
  type: SET_CLIENTS_LIST,
  payload: clients
})
