/* eslint-disable react/prop-types */
import React, { useState, useRef, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import cx from 'clsx'
import Dropdown from 'components/Dropdown'
import Bell from 'assets/images/bell-outlined.svg'
import Close from 'assets/images/close-icon.svg'
import Tabs from 'components/Tabs'
import Check from 'assets/images/check.svg'
import { __RouterContext } from 'react-router'
import ROUTES from 'routes/routes'
import APIs from 'api'
import useTranslate from 'hooks/useTranslate'
import s from './Notifications.module.scss'

function NotificationsBar() {
  const [showNotificationsBar, setShowNotificationsBar] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState({ id: 0, title: 'All' })
  const [unreadCount, setUnreadCount] = useState(0)
  const [categoryUnreadCount, setCategoryUnreadCount] = useState(0)
  const [notificationsList, setNotificationsList] = useState([])
  const { location } = useContext(__RouterContext)
  const history = useHistory()
  const currentUrl = location.pathname
  const t = useTranslate('general.notifications')
  const dropdown = useRef(null)

  const TABS = [
    { key: 'unread', title: t(5) },
    { key: 'all', title: t(6) },
    { key: 'archived', title: t(7) }
  ]

  const handleTabIndexChange = (event, newIndex) => {
    setTabIndex(newIndex)
  }

  const handleChangeCategory = category => {
    setSelectedCategory(category)
    setTabIndex(0)
    if (dropdown.current) {
      dropdown.current.handleClose()
    }
  }

  const fetchNotificationsList = () => {
    const body = { per_page: -1 }

    if (tabIndex === 2) {
      body.archived = 1
    } else if (tabIndex === 1) {
      body.archived = 0
    } else {
      body.status = 'unread'
    }

    if (selectedCategory?.id !== 0) {
      body.category_id = selectedCategory.id
    }

    APIs.getAllNotifications({ body }).then(res => {
      setNotificationsList(res?.data)
    })

    APIs.getUnreadNotificationsCount({
      body: { category_id: selectedCategory.id }
    }).then(res => {
      const unreadCount = res?.data?.unread_count
      setCategoryUnreadCount(unreadCount)
    })
  }

  const fetchUnreadNotificationsCount = () => {
    APIs.getUnreadNotificationsCount().then(res => {
      const unreadCount = res?.data?.unread_count
      setUnreadCount(unreadCount)
    })
  }

  useEffect(() => {
    const allInfo = { id: 0, title: 'All' }
    APIs.getNotificationsCategory().then(res => {
      const categoriesWithAll = [allInfo, ...res.data]
      setCategories(categoriesWithAll)
      setSelectedCategory(allInfo)
    })
  }, [])

  useEffect(() => {
    fetchUnreadNotificationsCount()
  }, [])

  useEffect(() => {
    fetchUnreadNotificationsCount()
  }, [currentUrl])

  useEffect(() => {
    fetchNotificationsList()
  }, [selectedCategory, tabIndex])

  useEffect(() => {
    if (showNotificationsBar) {
      fetchNotificationsList()
    }
  }, [showNotificationsBar])

  const handleMarkAsAllRead = () => {
    const body =
      selectedCategory?.id !== 0 ? { category_id: selectedCategory.id } : {}

    APIs.markAllAsReadAllNotifications({ body }).then(() => {
      fetchNotificationsList()
      fetchUnreadNotificationsCount()
    })
  }

  const handleViewDetails = (
    notificationId,
    categoryId,
    notificationData,
    notificationType
  ) => {
    APIs.markAsReadNotification({ params: { id: notificationId } }).then(() => {
      setShowNotificationsBar(false)
      fetchNotificationsList()
      fetchUnreadNotificationsCount()

      const routeState = {
        data: notificationData,
        notificationId,
        categoryId,
        notificationType
      }

      if (categoryId === 1) {
        if (notificationData.audience === 'member') {
          history.push(ROUTES.TIME_ENTRY, routeState)
        } else {
          history.push(ROUTES.TIME_APPROVAL, routeState)
        }
      } else if (categoryId === 2) {
        if (notificationType === 'InvoiceSyncFailed') {
          history.push(ROUTES.INTEGRATIONS, routeState)
        } else {
          history.push(
            ROUTES.INVOICE_ITEM.replace(':invoiceId', notificationData.invoice_id),
            routeState
          )
        }
      } else {
        history.push(ROUTES.PLANNING, routeState)
      }
    })
  }

  return (
    <div>
      <button
        className={s.notificationIconContainer}
        data-testid="edit_cat"
        onClick={() => setShowNotificationsBar(true)}
      >
        <img src={Bell} alt="notification icon" />
        {unreadCount > 0 && (
          <span
            className={cx(s.badge, {
              [s.lessThanTen]: unreadCount < 10
            })}
          >
            {unreadCount}
          </span>
        )}
      </button>

      <div
        className={cx(s.NotificationsBar, {
          [s.NotificationsBarOpen]: showNotificationsBar
        })}
      >
        <div className={s.titleContainer}>
          <h1 className={s.title}>{t(0)}</h1>
          <button
            onClick={() => setShowNotificationsBar(false)}
            className={s.close}
            data-testid="edit_cat"
          >
            <img src={Close} alt="close icon" />
          </button>
        </div>
        <div className={s.titlesContainer}>
          <span className={s.dropdownTitle}>{t(1)}</span>
          <button
            onClick={() => handleMarkAsAllRead()}
            className={cx(s.markAsAllRead, {
              [s.disabled]: unreadCount === 0
            })}
            disabled={unreadCount === 0}
          >
            <img src={Check} alt="icon" className={s.icon} />
            {t(2)}
          </button>
        </div>
        <Dropdown
          ref={dropdown}
          placeholder={t(3)}
          classes={{
            root: s.dropdownRoot,
            popover: s.dropdownCurrencyPopover,
            input: s.dropdownInput,
            title: s.dropdownTitle,
            inputContainer: s.dropdownInputContainer,
            placeholder: s.dropdownPlaceholder
          }}
          inputContent={<span className={s.value}>{selectedCategory?.title}</span>}
          content={
            <div>
              {categories.map(item => (
                <button
                  className={cx(
                    s.item,
                    selectedCategory?.id === item.id ? s.selectedItem : ''
                  )}
                  key={item.id}
                  type="button"
                  onClick={() => handleChangeCategory(item)}
                >
                  <span>{item.title}</span>
                </button>
              ))}
            </div>
          }
        />

        <div>
          <Tabs
            classes={{ root: s.tabs }}
            items={TABS}
            activeTab={tabIndex}
            onChange={handleTabIndexChange}
            unreadCount={
              selectedCategory?.id === 0 ? unreadCount : categoryUnreadCount
            }
          />
        </div>
        {notificationsList.length > 0 &&
          notificationsList.map(item => (
            <div
              key={item.id}
              className={cx(s.unreadCardContainer, {
                [s.readCardContainer]: item.is_read
              })}
            >
              {!item.is_read && <span className={s.unreadBadge}></span>}
              <div>
                <h3 className={s.cardTitle}>{item.title}</h3>
                <p className={s.cardDes}>{item.message}</p>
              </div>
              <div className={s.cardFooterContainer}>
                <span className={s.cardDate}>{item.created_at}</span>
                <button
                  onClick={() =>
                    handleViewDetails(item.id, item.category_id, item.data, item.type)
                  }
                  className={s.cardBtn}
                >
                  {t(8)}
                </button>
              </div>
            </div>
          ))}
        {/* <div className={s.unreadCardContainer}>
          <span className={s.unreadBadge}></span>
          <div>
            <h3 className={s.cardTitle}>New Time Entry Waiting Approval</h3>
            <p className={s.cardDes}>
              John Doe has submitted a time entry for the week of October 9–15, 2024,
              and is waiting for your approval.
            </p>
          </div>
          <div className={s.cardFooterContainer}>
            <span className={s.cardDate}>2:15 PM, October 16, 2024</span>
            <button className={s.cardBtn}>{t(8)}</button>
          </div>
        </div> */}
        {/* <div className={s.readCardContainer}>
          <div>
            <h3 className={s.cardTitle}>New Time Entry Waiting Approval</h3>
            <p className={s.cardDes}>
              John Doe has submitted a time entry for the week of October 9–15, 2024,
              and is waiting for your approval.
            </p>
          </div>
          <div className={s.cardFooterContainer}>
            <span className={s.cardDate}>2:15 PM, October 16, 2024</span>
            <button className={s.cardBtn}>{t(8)}</button>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default NotificationsBar
