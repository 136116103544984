/* eslint-disable no-console */
/* eslint-disable no-return-assign */
import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import cx from 'clsx'
import {
  getInitialDataActions,
  setOpenPopupTypeAction
} from 'store/thunks/subscription'
import commaNumber from 'comma-number'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import SnackManager from 'libs/snack'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import { useDebounce } from 'use-debounce'

import useGa from 'hooks/useGa'
import APIs from 'api'
import PaymentForm from 'components/PaymentForm'

import Loading from 'components/Loading'
import { store } from 'store/config'
import useTranslate from 'hooks/useTranslate'
import s from './PlanRenewal.module.scss'

function PlanRenewal({
  inputValue,
  isYearly,
  isSwitchYearly,
  defaultPaymentMethod,
  selectedCurrency,
  usedQuantity,
  handleClose,
  ...props
}) {
  const { overview } = props
  const [memberCount, setMemberCount] = useState({ current: '0', updated: '0' })
  const [debouncedMemberCount] = useDebounce(memberCount, 300)
  const [radioValue, setRadioValue] = useState('main') // main, other
  const [isDisable, setIsDisable] = useState(false)
  const [isSmallerCount, setIsSmallerCount] = useState(false)
  const [showErrorText, setShowErrorText] = useState(false)
  const [modalData, setModalData] = useState({})
  const dispatch = useDispatch()
  const ga = useGa('subscription')
  const t = useTranslate('subscription')
  const { plansList } = store.getState().subscription
  const proPlansList = plansList?.filter(plan => plan.plan_type === 'pro')

  function findIdByKey(key) {
    if (key) {
      const obj = proPlansList.find(item => item.invoice_interval === 'year')
      return obj ? obj.id : null
    }
    const obj = proPlansList?.find(item => item.invoice_interval === 'month')
    return obj ? obj.id : null
  }
  const planId = findIdByKey(isSwitchYearly)

  const isUpdatedSmaller = () => {
    if (Number(memberCount.updated) < usedQuantity) {
      setIsSmallerCount(true)
      setIsDisable(true)
      setShowErrorText(true)
    } else {
      setIsSmallerCount(false)
      setIsDisable(false)
      setShowErrorText(false)
    }
  }

  useEffect(() => {
    const calculatePrice = () => {
      const newPrice = Number(selectedCurrency?.amount) * Number(memberCount?.updated)
      const roundedPrice = Number(newPrice?.toFixed(2)) // Round to 2 decimal places and convert back to number
      setModalData({ ...modalData, price: roundedPrice })
    }

    calculatePrice()
  }, [selectedCurrency, memberCount])

  useEffect(() => {
    const quantityStr = String(overview.quantity)
    setMemberCount({ current: quantityStr, updated: quantityStr })
  }, []) // eslint-disable-line

  useEffect(() => {
    isUpdatedSmaller()
  }, [memberCount]) // eslint-disable-line

  useEffect(() => {
    if (Number(debouncedMemberCount.updated)) {
      setModalData({
        ...modalData,
        isSmallerCount,
        addedCount: Number(memberCount.updated) - Number(memberCount.current),
        current: memberCount.current,
        updated: memberCount.updated,
        currency: selectedCurrency?.currency
      })
    }
  }, [debouncedMemberCount.updated])

  const handleSetMemberCount = (key, value) => {
    if (value.length) {
      setMemberCount(prevState => ({ ...prevState, [key]: value[0] }))
    } else {
      setMemberCount(prevState => ({ ...prevState, [key]: '' }))
    }
  }

  const handlePayment = async (id, customerInfo) => {
    const info = {
      payment_method: id,
      plan_id: planId,
      number_of_members: inputValue,
      pay_by: 'stripe',
      first_name: customerInfo.firstName,
      last_name: customerInfo.lastName,
      country: customerInfo.country,
      postal_code: customerInfo.postalCode
    }
    const subscriptionInfo = {
      payment_method_id: null,
      plan_id: planId,
      number_of_members: inputValue
    }
    await APIs.addNewPaymentMethod({ body: info }).then(
      res => (subscriptionInfo.payment_method_id = res.data.id)
    )
    if (isSwitchYearly) {
      const body = {
        payment_method_id: subscriptionInfo.payment_method_id,
        plan_id: planId,
        number_of_members: memberCount.current
      }
      return APIs.changeSubscription({ body }).then(() => {
        ga('switched to yearly')
        SnackManager.success(t(80))
        return dispatch(setOpenPopupTypeAction(''))
      })
    }
    const body = {
      payment_method_id: defaultPaymentMethod.id,
      number_of_members: memberCount.updated
    }
    return APIs.changeSubscription({ body }).then(() => {
      ga('changed number of members')
      SnackManager.success(t(80))
      return dispatch(setOpenPopupTypeAction(''))
    })
  }

  const handleChangeRadio = value => setRadioValue(value)

  const handleUpdatePlan = () => {
    const info = {
      payment_method_id: defaultPaymentMethod.id,
      number_of_members: memberCount.updated
    }
    APIs.changeSubscription({ body: info })
      .then(() => {
        ga('Renew subscription')
        SnackManager.success(t(80))
        dispatch(getInitialDataActions())
        handleClose()
        window.location.reload()
        return dispatch(setOpenPopupTypeAction(''))
      })
      .catch(err => {
        if (typeof err === 'string') {
          SnackManager.error(err)
        } else if (err?.message) {
          SnackManager.error(err.message)
        } else if (err && typeof err === 'object') {
          Object.entries(err).forEach(([key, messages]) => {
            messages.forEach(message => {
              SnackManager.error(message)
            })
          })
        } else {
          SnackManager.error('An error occurred. Please try again.')
        }
      })
  }

  const handleCancel = () => {
    setRadioValue('main')
  }

  return (
    <div className={s.wrapper}>
      <div className={s.head}>
        <h2 className={s.title}>Renew subscription</h2>
      </div>

      <div className={s.inputContainer}>
        <span className={s.label}>{t(86)}</span>
        <div className={s.inputWrapper}>
          <TextField
            classes={{ root: s.input }}
            value={memberCount.updated}
            inputProps={{ testid: 'members-input' }}
            onChange={e =>
              handleSetMemberCount('updated', e.target.value.match(/[0-9]+/g) || [''])
            }
            variant="outlined"
          />
        </div>
        <Collapse in={showErrorText}>
          <span className={s.errorText}>
            {t(142)} {overview.used_quantity} {t(143)}
            {t(144)}
          </span>
        </Collapse>
      </div>

      <div className={s.calcBox}>
        <p className={s.unitAmount}>
          Unit amount:
          {'  '}
          <span className={s.num}>{selectedCurrency?.amount}</span>{' '}
          <span className={s.type}>{selectedCurrency?.currency}</span>
        </p>
      </div>

      <div className={cx({ [s.isDisable]: isDisable })}>
        {!isDisable ? (
          <>
            {!isSmallerCount ? (
              <>
                <div className={s.innerTitle}>
                  <h3 className={s.title}>{t(98)}</h3>
                </div>
                <div className={s.radios}>
                  <RadioGroup
                    value={radioValue}
                    onChange={e => handleChangeRadio(e.target.value)}
                  >
                    <FormControlLabel
                      classes={{ root: s.radioLabelRoot, label: s.radioLabel }}
                      value="main"
                      data-testid="current-card"
                      control={<Radio color="primary" classes={{ root: s.radio }} />}
                      label={
                        <span className={s.radioName}>
                          {t(99)} ****{defaultPaymentMethod?.last4}
                        </span>
                      }
                    />
                    <FormControlLabel
                      classes={{ root: s.radioLabelRoot, label: s.radioLabel }}
                      data-testid="other-card"
                      value="other"
                      control={<Radio color="primary" classes={{ root: s.radio }} />}
                      label={<span className={s.radioName}>{t(100)}</span>}
                    />
                  </RadioGroup>
                </div>
              </>
            ) : null}
          </>
        ) : null}

        <Collapse in={radioValue === 'other'}>
          <PaymentForm
            showDetails={false}
            handlePayment={handlePayment}
            showButton
            buttonText={t(101)}
            onCancel={handleCancel}
          />
        </Collapse>

        <div className={s.submit}>
          <div className={s.calcBox}>
            <p className={s.total}>
              Total amount:
              {'  '}
              <span className={s.num}>
                {modalData ? commaNumber(modalData?.price) : <Loading show />}
              </span>{' '}
              <span className={s.type}>{selectedCurrency?.currency}</span>
            </p>
          </div>
          {radioValue !== 'other' && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdatePlan}
              classes={{ root: s.btn, label: s.btnLabel }}
              disabled={isDisable}
              data-testid="submit"
            >
              Pay
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
PlanRenewal.propTypes = {
  inputValue: PropTypes.number.isRequired,
  isYearly: PropTypes.bool.isRequired,
  isSwitchYearly: PropTypes.bool,
  overview: PropTypes.shape
}
PlanRenewal.defaultProps = {
  isSwitchYearly: false,
  overview: null
}

const mapStateToProps = state => {
  return {
    overview: state.subscription.overview
  }
}

export default connect(mapStateToProps, null)(memo(PlanRenewal))
